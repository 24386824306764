<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "OAuth",
  methods: {
    ...mapActions(["OAuth"]),
  },
  async mounted() {
    let search = location.hash.substring(1);
    let obj = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    if (obj && obj.access_token) {
      let oauth = await this.OAuth(obj.access_token);
      if (oauth) {
        if (obj.state) {
          let url;
          let params;
          try {
            url = new URL(obj.state);
          } catch {
            url = new URL("https://" + obj.state);
          }
          if (window.location.host !== url.host) {
            params = new URLSearchParams(url.search);
            params.append("refresh", oauth.refresh);
            params.append("access_token", oauth.access_token);
            url.search = params;
          }
          window.open(url, "_self");
        } else {
          this.$router.push({ name: "MainPage" });
        }
      } else {
        this.$router.push({ name: "MainPage" });
      }
    } else {
      this.$router.push({ name: "MainPage" });
    }
  },
};
</script>

<style>
</style>